import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export default function useGetAccessToken() {
  const [tkn, setTkn] = useState(null);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getAccessToken = async () => {
      const domain = process.env.REACT_APP_AUTH0_DOMAIN;

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${domain}/api/v2/`,
          },
        });
        setTkn(accessToken);
      } catch (e) {
        console.log(e.message);
      }
    };

    getAccessToken();
  }, [getAccessTokenSilently]);

  return tkn;
}
