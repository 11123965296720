import * as React from "react";
import { Box } from "@mui/material";

import { H3 } from "../common/typography";
import ActionBar from "./ActionBar";
import EventsPanel from "./EventsPanel";

import { abortImport, confirmImport, validate } from "../../utils/fetch.utils";

const ImportProgress = ({ collectionId, filename, status, setStatus }) => {
  const [events, setEvents] = React.useState([]);
  const [hasErrors, setHasErrors] = React.useState(false);

  React.useEffect(() => {
    if (events.length) {
      setHasErrors(events.some((e) => e.status === "error"));
    }
  }, [events]);

  const handleTriggerValidation = async () => {
    const resp = await validate(collectionId, filename);
    setEvents((prevState) => [...prevState, ...resp.results]);
    setStatus("validated");
  };

  const handleAbort = async () => {
    const resp = await abortImport(collectionId, filename);
    setEvents((prevState) => [...prevState, resp]);
    setStatus("aborted");
  };

  const handleConfirm = async () => {
    const resp = await confirmImport(collectionId, filename);
    setEvents((prevState) => [...prevState, resp]);
  };

  return (
    <Box sx={{ margin: "0 0 1rem" }}>
      <H3>Import Progress -- status: {status}</H3>
      <EventsPanel {...{ events }} />
      {status !== "aborted" ? (
        <ActionBar
          {...{
            handleAbort,
            handleTriggerValidation,
            handleConfirm,
            hasErrors,
            status,
          }}
        />
      ) : null}
    </Box>
  );
};

export default ImportProgress;
