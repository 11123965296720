import React from "react";
// import { useParams } from "react-router-dom";

import { Typography } from "@mui/material";

// import Item from "./Item";
import DataTable from "../../../components/common/DataTable";
// import AddItemModal from "../../../components/AddItem";
import { H3 } from "../../../components/common/typography";

import useItemsContext from "../../../context/ItemsContext";
import useFetchItems from "../../../hooks/use-fetch-items.hook";

const initialState = {
  _id: "",
  title: "",
  slug: "",
  // published: false,
};

const Items = ({ collectionId, /* currentColl,*/ itemTabType = "view" }) => {
  // const { itemId } = useParams();

  const { count, documents, dbSize /* , handleAdd */, handleDelete } =
    useItemsContext();
  // const [modalVisible, setModalVisible] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [pagination, setPagination] = React.useState({ page: 0, limit: 10 });

  // const navigate = useNavigate();

  useFetchItems(searchTerm, pagination, collectionId);

  // const handleAddItem = async (data) => {
  //   const resp = await handleAdd(collectionId, data);
  //   if (resp._id) setModalVisible(false);
  // };

  const handleDeleteItem = async (itemId) => {
    await handleDelete(collectionId, itemId);
  };

  // const handleSelect = (itemId) => {
  //   navigate(`/collections/${collectionId}/items/${itemId}`, { replace: true });
  // };

  return (
    <>
      {/* <AddItemModal
        {...{
          open: modalVisible,
          onClose: () => setModalVisible(false),
          onSubmit: handleAddItem,
          currentColl,
        }}
      /> */}

      {/* {itemTabType === "edit" && itemId && (
        <Item
          {...{
            title: "Edit Item",
            description: "keep on editing...",
            currentColl,
            itemId,
          }}
        />
      )} */}
      {itemTabType === "view" && (
        <>
          <H3>Items</H3>
          <Typography>Currently {dbSize} items in the database.</Typography>
          <DataTable
            {...{
              type: "items",
              initialState,
              documents,
              count,
              searchTerm,
              setSearchTerm,
              pagination,
              setPagination,
              // addItem: () => setModalVisible(true),
              handleDelete: handleDeleteItem,
              rowIsSelectable: false,
              deleteDisabled: process.env.REACT_APP_ENV === "prod",
            }}
          />
        </>
      )}
    </>
  );
};

export default Items;
