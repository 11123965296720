import * as React from "react";
import { Box } from "@mui/material";

import Import from "../../components/Import";
import Export from "../../components/Export";

const styling = {
  section: {
    marginTop: "1rem",
    paddingBottom: "2rem",
  },
  importMessage: {
    marginTop: "1rem",
    padding: "1rem 0",
  },
};

const ImportExport = ({ collectionId }) => {
  const [backUpExported, setBackUpExported] = React.useState(false);
  return (
    <>
      <Box sx={styling.section}>
        <Export
          collectionId={collectionId}
          setBackUpExported={setBackUpExported}
        />
      </Box>

      <Box sx={styling.section}>
        <Import collectionId={collectionId} disabled={!backUpExported} />
      </Box>
    </>
  );
};

export default ImportExport;
