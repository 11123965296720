import * as React from "react";
import { Box } from "@mui/material";

import CustomButton from "./common/CustomButton";
import { exportItems } from "../utils/fetch.utils";
import useCollectionsContext from "../context/CollectionsContext";
import slugify from "slugify";

const Export = ({
  collectionId,
  setBackUpExported,
  label = "Export Items",
}) => {
  const { current: currentColl, dataEnv } = useCollectionsContext();

  const handleExport = async () => {
    await exportItems(collectionId).then((blob) => {
      const now = Date.now("YYYYMMDD-HHmmss");
      const date = new Date(now).toISOString();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${slugify(currentColl?.name || collectionId)}-${
          dataEnv?.toUpperCase() || ""
        }-${date}.csv`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setBackUpExported(true);
    });
  };

  return (
    <Box sx={{ margin: "0 0 1rem" }}>
      <CustomButton variant="contained" onClick={handleExport} label={label} />
    </Box>
  );
};

export default Export;
