import React from "react";
import { Alert, Box } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond"; // https://pqina.nl/filepond/docs/
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

import { MdFileUploadOff } from "react-icons/md";

import ImportProgress from "./ImportProgress";
import { H3 } from "./common/typography";

import { getFilePondServerSettings } from "../utils/fetch.utils";

import "filepond/dist/filepond.min.css";
registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const Import = ({ collectionId, disabled }) => {
  const [status, setStatus] = React.useState("idle");
  const [files, setFiles] = React.useState([]);
  const [message, setMessage] = React.useState({});
  const [filename, setFilename] = React.useState(null);

  const filePondServerSettings = getFilePondServerSettings(collectionId);

  return (
    <Box sx={{ margin: "0 0 1rem" }}>
      <H3>Import Items</H3>
      {disabled ? (
        <Alert severity="warning">
          Please export the collection to enable the Import Feature.
        </Alert>
      ) : (
        <Alert severity="success">Yay! Import is now enabled.</Alert>
      )}
      <Box
        sx={{
          border: "3px dashed #ccc",
          padding: "2rem 2rem 1rem",
          margin: "2rem 0 2rem",
          minHeight: "145px",
        }}
      >
        {!disabled ? (
          <FilePond
            {...{
              files,
              name: "csvfile",
              maxFiles: 1,
              maxFileSize: "5MB",
              acceptedFileTypes: ["text/csv"],
              onupdatefiles: setFiles,
              labelIdle:
                'Drag & Drop your CSV file or <span class="filepond--label-action">Browse</span>',
              credits: false,
              server: {
                url: filePondServerSettings.url,
                process: {
                  headers: filePondServerSettings.headers,
                  onload: (res) => {
                    setMessage(JSON.parse(res));
                    setFilename(JSON.parse(res).filename);
                    setStatus("cached");
                  },
                },
              },
            }}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: "4.5rem",
              marginTop: "0",
              textAlign: "center",
              opacity: 0.5,
            }}
          >
            <MdFileUploadOff />
          </Box>
        )}
      </Box>
      {message?.status ? (
        <Alert severity={message.status === 200 ? "success" : "error"}>
          {message.message}
        </Alert>
      ) : null}
      {!disabled && Boolean(filename) ? (
        <ImportProgress {...{ collectionId, filename, status, setStatus }} />
      ) : null}
    </Box>
  );
};

export default Import;
