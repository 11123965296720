import * as React from "react";

import TableCell from "@mui/material/TableCell";
import { GoEye, GoTrashcan } from "react-icons/go";
import ToolbarButton from "../ToolbarButton";

const classes = {
  root: {
    height: "44px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 0,
    zIndex: 1, // not sure this is working, not able to test it yet. Need a case for collection delete
  },
};

const Tools = ({
  itemData,
  handleSelect,
  handleDelete,
  deleteDisabled = true,
}) => {
  // custom_keys don't have _ids
  const itemIdentifier = itemData._id || itemData.slug;

  return (
    <TableCell sx={classes.root}>
      {handleSelect ? (
        <ToolbarButton
          {...{
            label: <GoEye />,
            type: "primary",
            disabled: !handleSelect,
            onClick: () => {
              handleSelect(itemIdentifier);
            },
          }}
        />
      ) : null}
      {handleDelete ? (
        <ToolbarButton
          {...{
            label: <GoTrashcan />,
            type: "delete",
            disabled: deleteDisabled,
            onClick: () => {
              handleDelete(itemIdentifier);
            },
          }}
        />
      ) : null}
    </TableCell>
  );
};

export default Tools;
