import { createContext, useContext, useState } from "react";
import {
  addCollection,
  deleteCollection,
  updateCollection,
} from "../utils/fetch.utils";

const CollectionsContext = createContext(null);

export const CollectionsProvider = ({ children }) => {
  const [collections, setCollections] = useState({ loading: true });
  const [dataEnv, setDataEnv] = useState(null);

  const handleAdd = async (data) => {
    const resp = await addCollection(data);

    if (resp.data._id) {
      setCollections({
        count: collections.count + 1,
        documents: [...collections.documents, resp.data],
      });
      return resp.data;
    } else {
      // TODO: deal with api error here...
    }
  };

  const handleDelete = async (_id) => {
    const resp = await deleteCollection(_id);

    if (resp.status === 200) {
      setCollections({
        count: collections.count - 1,
        documents: collections.documents.filter((coll) => coll._id !== _id),
      });
    } else {
      // TODO: handle error nicely
      console.error("there was a problem");
    }
  };

  const handlePublish = async (value, _id) => {
    const data = { published: value };
    await updateCollection(_id, data);
    const documents = collections.documents.map((doc) =>
      doc._id === _id ? { ...doc, ...data } : doc
    );
    setCollections((prevState) => ({ ...prevState, documents }));
  };

  const handleSelect = (_id) => {
    const current = collections.documents.find((doc) => doc._id === _id);
    setCollections((prevState) => ({ ...prevState, current }));
  };

  const setCurrentCollection = (current) => {
    setCollections((prevState) => ({ ...prevState, loading: false, current }));
  };

  return (
    <CollectionsContext.Provider
      value={{
        ...collections,
        setCollections,
        handleAdd,
        handleDelete,
        handlePublish,
        handleSelect, // eliminate?
        setCurrentCollection,
        setDataEnv,
        dataEnv,
      }}
    >
      {children}
    </CollectionsContext.Provider>
  );
};

export default function useCollectionsContext() {
  return useContext(CollectionsContext);
}
